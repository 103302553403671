<template>
  <div class="vx-row mb-12">
    <div class="vx-col mb-15 w-full space-y-1">
      <div class="flex flex-row w-1/2">
        <div class="w-1/2">
          <span class="text-lg">Delivery Date</span>
        </div>
        <div class="w-full">
          <date-range-picker
            style="min-height: 40px"
            class="w-full"
            ref="picker"
            opens="center"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="true"
            v-model="date"
            :linkedCalendars="true"
          >
            <template v-slot:input="picker" style="min-width: 350px">
              {{ datePickerDateFormat(picker.startDate) }} -
              {{ datePickerDateFormat(picker.endDate) }}
            </template>
          </date-range-picker>
        </div>
      </div>
      <div class="flex flex-row w-1/2">
        <div class="w-1/2">
          <span class="text-lg">Shipment Number </span>
        </div>
        <div class="w-full">
          <multiselect
            class="selectExample"
            v-model="shipmentNumber"
            :options="optionShipmentNumber"
            :multiple="true"
            :allow-empty="true"
            :max-height="100"
            :limit="3"
            placeholder="Type to search"
            :searchable="true"
            :custom-label="customLabelTP"
            @search-change="handleBTShipment"
          />
          <!-- <span v-show="errors.has('vehicle')" class="text-danger"
            >Vehicle is required</span
          > -->
        </div>
      </div>

      <div class="vx-col w-1/2 mt-8 flex justify-end space-x-1">
        <vs-button
          @click="onClickBtnFilter"
          size="medium"
          color="primary"
          icon-pack="feather"
          icon="icon-search"
          title="Search"
          :disabled="detail"
        >
          Filter</vs-button
        >
        <vs-button
          @click="onClickBtnReset"
          size="medium"
          color="warning"
          icon-pack="feather"
          icon="icon-rotate-ccw"
          title="Reset"
          :disabled="detail"
        >
          Reset</vs-button
        >
      </div>
    </div>
    <div v-bind:class="[detail ? '' : '', detailHide]">
      <vs-table
        v-if="!detail"
        search
        stripe
        border
        descriptions
        :sst="true"
        :data="table.data"
        :max-items="table.length"
        :total="table.total"
        @change-page="handleChangePage"
        @search="handleSearch"
        @sort="handleSort"
      >
        <template slot="header" v-if="detail == false">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div
              class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ this.table.start }} - {{ this.table.end }} of
                {{ this.table.total }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item
                v-for="item in table.limits"
                :key="item"
                @click="handleChangelength(item)"
              >
                <span>{{ item }}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </template>
        <template slot="thead">
          <vs-th sort-key="">Action</vs-th>
          <vs-th sort-key="shipment_number">Shipment Document</vs-th>
          <vs-th sort-key="vehicle_number">Vehicle ID</vs-th>
          <vs-th sort-key="driver_name">Driver Name</vs-th>
          <vs-th sort-key="delivery_plan_date_str">Delivery Date</vs-th>
          <vs-th sort-key="count_delivery_order"># of DO</vs-th>
          <vs-th sort-key="total_weight">Total Weigh (Kg)</vs-th>
          <vs-th sort-key="total_volume">Total Volume (m3)</vs-th>
          <vs-th sort-key="status">Status Shipment</vs-th>
          <vs-th sort-key="status">Status Distribution</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <div class="vx-input-group flex">
                <vs-button
                  v-if="data[indextr].status_shipment == 'waiting'"
                  size="small"
                  color="warning"
                  icon-pack="feather"
                  icon="icon-edit-2"
                  style="margin-right: 5px"
                  title="Edit"
                  :disabled="
                    data[indextr].status_shipment != 'waiting' || detail == true
                  "
                  @click="viewDetail(tr)"
                />
                <vs-button
                  size="small"
                  color="success"
                  icon-pack="feather"
                  icon="icon-eye"
                  style="margin-right: 5px"
                  title="View"
                  @click="viewDetail(tr)"
                  v-if="data[indextr].status_shipment != 'waiting'"
                />
              </div>
            </vs-td>

            <vs-td :data="data[indextr].shipment_number">
              {{ data[indextr].shipment_number }}
            </vs-td>

            <vs-td :data="data[indextr].vehicle_number_add">
              <ul>
                <li>
                  <ul>
                    <li
                      :data="tr"
                      :key="indextr"
                      v-for="(tr, indextr) in data[indextr].vehicle_number_add"
                    >
                      {{ tr }}
                    </li>
                  </ul>
                </li>
              </ul>
            </vs-td>
            <vs-td :data="data[indextr].driver_name_add">
              <ul>
                <li>
                  <ul>
                    <li
                      :data="tr"
                      :key="indextr"
                      v-for="(tr, indextr) in data[indextr].driver_name_add"
                    >
                      {{ tr }}
                    </li>
                  </ul>
                </li>
              </ul>
            </vs-td>
            <vs-td :data="data[indextr].delivery_date">
              {{ data[indextr].delivery_date }}
            </vs-td>
            <vs-td :data="data[indextr].total_do">
              {{ data[indextr].total_do }}
            </vs-td>
            <vs-td :data="data[indextr].total_weight">
              {{ data[indextr].total_weight }}
            </vs-td>
            <vs-td :data="data[indextr].total_volume">
              {{ data[indextr].total_volume }}
            </vs-td>
            <vs-td :data="data[indextr].status_shipment">
              {{ data[indextr].status_shipment }}
            </vs-td>
            <vs-td :data="data[indextr].status_distribution">
              {{
                data[indextr].status_distribution == false
                  ? "Not Distributed"
                  : "Distributed"
              }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        v-if="!detail"
        style="padding-top: 5px"
        :total="table.totalPage"
        v-model="setPage"
      />
      <div class="vx-row">
        <div class="vx-col sm:w-6/12 w-full">
          <!-- <vs-button
            class="mr-3 mb-2"
            v-on:click="ExportForm()"
            v-if="table.data.length > 0"
            >Print</vs-button
          > -->
          <!-- <vs-button
            class="mr-3 mb-2"
            v-on:click="ReturnTransportPlan()"
            v-if="table.data.length > 0"
            >Return</vs-button
          > -->
        </div>
      </div>
    </div>
    <!--view detail ----->
    <transition name="detail-fade">
      <div v-if="detail" v-bind:class="[detail ? detailHide : '', detailHide]">
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>

          <!-- form component -->
          <Table3 @close="handleClose" :ID="IDData"></Table3>
        </vs-row>
      </div>
    </transition>
  </div>
</template>
<script>
import moment from "moment";
import Table3 from "../detailFormDsitribution.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: {
    Table3,
    DateRangePicker,
  },
  mounted() {
    this.ShipmentNumber();
  },
  data() {
    return {
      table: this.tableDefaultState(),
      detailShow: "core vx-col md:w-1/12 w-full mb-base ",
      detailShowV2: "core vx-col md:w-11/12 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      selected: this.$store.state.tms.tms.selected,
      detail: false,
      dataViews: [],
      optionShipmentNumber: [],
      IDData: null,
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [5, 10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    datePickerDateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("dddd, MMMM Do YYYY");
      }
      return a;
    },
    handleSearch(searching) {
      this.table.search = searching;
      if (searching == "") {
        this.table.length = 10;
      }
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.detail = false;
      this.dataViews = [];
      this.IDData = null;
    },
    onClickBtnFilter() {
      this.getData();
    },
    onClickBtnReset() {
      this.$store.commit("tms/tms/setDate", {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      });
      this.$store.commit("tms/tms/setShipment", []);
      this.getData();
    },
    getData() {
      let shipmentCode = [];
      if (this.$store.state.tms.tms.shipment != null) {
        for (
          let index = 0;
          index < this.$store.state.tms.tms.shipment.length;
          index++
        ) {
          shipmentCode.push(
            this.$store.state.tms.tms.shipment[index].shipment_number
          );
        }
      }

      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/transport-code/all-load-distribution", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            date_from: moment(this.$store.state.tms.tms.date.startDate).format(
              "YYYY-MM-DD"
            ),
            date_to: moment(this.$store.state.tms.tms.date.endDate).format(
              "YYYY-MM-DD"
            ),
            shipment_code: shipmentCode,
          },
        })
        .then((resp) => {
          console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            if (resp.data.records != null) {
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.records;

              console.log("weee", this.table.data);

              this.setStartEnd();
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    ShipmentNumber() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/transport-code/all-load-distribution", {
          params: {
            length: 20,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            // date_from: moment(this.$store.state.tms.tms.date.startDate).format(
            //   "YYYY-MM-DD"
            // ),
            // date_to: moment(this.$store.state.tms.tms.date.endDate).format(
            //   "YYYY-MM-DD"
            // ),
          },
        })
        .then((resp) => {
          console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            if (resp.data.records != null) {
              this.optionShipmentNumber = resp.data.records;
              // console.log("weee2222", this.optionShipmentNumber);
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleBTShipment(query) {
      if (query.length < 3) {
        return;
      } else {
        let params = {
          ...this.table,
        };
        params.search = query;
        this.getShipmentNumber(params);
      }
    },
    viewDetail(data) {
      console.log(">>>>", data);
      this.detail = true;
      this.IDData = data.id;
    },
    customLabelTP({ shipment_number }) {
      return `${shipment_number}`;
    },
  },

  watch: {
    detail() {
      this.getData();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
    date: {
      get() {
        return this.$store.state.tms.tms.date;
      },
      set(val) {
        this.$store.commit("tms/tms/setDate", val);
      },
    },
    shipmentNumber: {
      get() {
        return this.$store.state.tms.tms.shipment;
      },
      set(val) {
        this.$store.dispatch("tms/tms/updateShipment", val);
      },
    },
  },
  filters: {
    formatDate(val) {
      return moment(val)
        .add(-7, "h")
        .utc()
        .local()
        .format("DD MMM YYYY HH:mm:ss");
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
