<template>
  <vx-card :title="title">
    <vs-tabs :value="active">
      <vs-tab label="New">
        <New></New>
      </vs-tab>
      <vs-tab label="Simulation">
        <div class="tab-text">
          <Simulation></Simulation>
        </div>
      </vs-tab>
      <vs-tab label="Released">
        <div class="tab-text">
          <Released></Released>
        </div>
      </vs-tab>
      <vs-tab label="Load Distribution">
        <div class="tab-text">
          <LoadDistribution></LoadDistribution>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import New from "./_tab/table-new.vue";
import Simulation from "./_tab/table-simulation.vue";
import Released from "./_tab/table-released.vue";
import LoadDistribution from "./_tab/table-driver-distribution.vue";
export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    New,
    Simulation,
    Released,
    LoadDistribution,
  },
  data() {
    return {
      title: "Transport Plan",
    };
  },
};
</script>
