<template>
  <vx-card>
    <div class="vx-col mt-6 mb-6 w-full">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Shipment Document</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <div class="vx-col w-1/3">
            <vs-input
              class="w-full"
              v-model="table.data.shipment_number"
              :disabled="true"
              v-validate="'required'"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Shipment Date</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <div class="vx-col w-1/3">
            <vs-input
              class="w-full"
              v-model="table.data.delivery_plan_date"
              :disabled="true"
              type="date"
              v-validate="'required'"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span># of DO/PI</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <div class="vx-col w-1/3">
            <vs-input
              class="w-full"
              v-model="table.data.of_do"
              :disabled="true"
              v-validate="'required'"
            />
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div class="vx-col mt-6 mb-6 w-full">
      <vs-table :data="table.dataLine" stripe>
        <template slot="thead">
          <vs-th>DO/PI Number</vs-th>
          <vs-th>Ship-to Name</vs-th>
          <vs-th>Ship-to Address</vs-th>
          <vs-th>SKU Code/Name</vs-th>
          <vs-th>HU</vs-th>
          <vs-th>QTY</vs-th>
          <vs-th
            v-for="(vehicle, index) in table.data.vehicle_add"
            :key="index"
            >{{ vehicle.vehicle_number }}</vs-th
          >
        </template>
        <template slot-scope="{ data }">
          <vs-tr v-for="(row, index) in data" :key="index">
            <vs-td>{{ row.delivery_order_code }}</vs-td>
            <vs-td>{{ row.shipto_name }}</vs-td>
            <vs-td>{{ row.shipto_address }}</vs-td>
            <vs-td>{{ row.item_code }}-{{ row.item_name }}</vs-td>
            <vs-td>{{ row.item_unit }}</vs-td>
            <vs-td>{{ row.quantity }}</vs-td>
            <vs-td
              v-for="(vehicle, indextr) in row.vehicle_distribution"
              :key="'tt' + indextr"
            >
              <vs-input
                v-model="vehicle.quantity"
                type="number"
                :min="0"
                :max="row.quantity"
                @input="checkValue($event, row, indextr)"
                :disabled="showData"
              ></vs-input>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/5 w-full space-x-4">
        <vs-button @click="saveData" color="primary" v-if="!showData"
          >Save</vs-button
        >
        <vs-button @click="resetData" color="warning" v-if="!showData"
          >Reset</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>

<script>
export default {
  props: {
    ID: {
      type: Number,
    },
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      table: this.tableDefaultState(),
      showData: false,
      vehicleAdd: [],
      vehicleIsinsert: false,
    };
  },
  watch: {},
  methods: {
    tableDefaultState() {
      return {
        data: [],
        dataLine: [],
        dataLineTemp: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/transport-code/detail-load-distribution/" + this.ID)
        .then((res) => {
          console.log("response", res);
          this.table.data = res.data;
          this.showData = this.table.data.status !== "waiting" ? false : false;
          console.log("showData", this.showData);
          const itemLines = this.table.data.delivery_order_line.flatMap(
            (order) =>
              order.item_line.map((item) => ({
                delivery_order_id: order.delivery_order_id,
                delivery_order_code: order.delivery_order_code,
                shipto_name: order.shipto_name,
                shipto_address: order.shipto_address,
                item_code: item.item_code,
                item_name: item.item_name,
                item_unit: item.item_unit,
                quantity: item.quantity,
                item_unit_id: item.item_unit_id,
                vehicle_distribution: item.vehicle_distribution,
              }))
          );

          const seenDeliveryOrderIds = new Set();
          this.table.dataLine = itemLines.map((item) => {
            const isDuplicate = seenDeliveryOrderIds.has(
              item.delivery_order_id
            );
            seenDeliveryOrderIds.add(item.delivery_order_id);
            return {
              ...item,
              qtyCounting: item.quantity,
              delivery_order_code: isDuplicate ? "" : item.delivery_order_code,
              shipto_name: isDuplicate ? "" : item.shipto_name,
              shipto_address: isDuplicate ? "" : item.shipto_address,
              isedited: item.vehicle_distribution != null ? true : false,
            };
          });

          this.table.dataLine.forEach((item) => {
            console.log("pppppp", item.isedited);
            if (!item.isedited) {
              //looping value on vehicle_add and append to item.vehicle_distribution
              var itemDescr = [];
              this.table.data.vehicle_add.forEach((vehicle, index) => {
                itemDescr.push({
                  vehicle_id: vehicle.vehicle_id,
                  vehicle_number: vehicle.vehicle_number,
                  driver_id: vehicle.driver_id,
                  driver_name: vehicle.driver_name,
                  quantity: 0,
                });
              });
              itemDescr[0].quantity = item.quantity;
              item.vehicle_distribution = itemDescr;
            }
            if (!this.vehicleIsinsert) {
              this.vehicleAdd = item.vehicle_distribution;
              this.vehicleIsinsert = true;
            }
          });

          if (!this.vehicleIsinsert && this.vehicleAdd.length != 0) {
            this.table.data.vehicle_add = this.vehicleAdd;
          }
          this.table.data.vehicle_add = this.vehicleAdd;

          //duplicate dataLine to dataLineTemp
          this.table.dataLineTemp = JSON.parse(
            JSON.stringify(this.table.dataLine)
          );
          console.log("table2", this.table.dataLine);
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: err.response.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
          });
        });
    },
    saveData() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure to Save this data?",
        accept: (e) => {
          this.save();
        },
      });
    },
    save() {
      //check on dataLine if total quantity  is less than the dataLine.quantity
      for (let i = 0; i < this.table.dataLine.length; i++) {
        var totalQty = 0;
        this.table.dataLine[i].vehicle_distribution.forEach((item) => {
          totalQty += Number(item.quantity);
        });
        if (totalQty != this.table.dataLine[i].quantity) {
          this.$vs.notify({
            title: "Error",
            text: "please check again the quantity distribution, some data quantity is not distributed all",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
          });

          return;
        }
      }

      var dataConvert = [];
      this.table.dataLine.forEach((item) => {
        var vehicle_distribution = [];
        var groupedItems = dataConvert.find(
          (data) => data.delivery_order_id === item.delivery_order_id
        );

        if (!groupedItems) {
          groupedItems = {
            delivery_order_id: item.delivery_order_id,
            item_line: [],
          };
          dataConvert.push(groupedItems);
        }

        var itemLine = groupedItems.item_line.find(
          (line) => line.item_unit_id === item.item_unit_id
        );

        if (!itemLine) {
          itemLine = {
            item_unit_id: item.item_unit_id,
            item_code: item.item_code,
            item_name: item.item_name,
            item_unit: item.item_unit,
            vehicle_data: [],
          };
          groupedItems.item_line.push(itemLine);
        }

        item.vehicle_distribution.forEach((vehicle) => {
          itemLine.vehicle_data.push({
            vehicle_id: vehicle.vehicle_id,
            driver_id: vehicle.driver_id,
            quantity: vehicle.quantity,
          });
        });
      });
      console.log("dataConvert", dataConvert);

      const postData = {
        transport_plan_id: this.ID,
        delivery_order_data: dataConvert,
      };
      console.log("postData", postData);
      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/transport-code/load-distribution", postData)
        .then((res) => {
          if (res.code == 200) {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Success",
              text: "Data has been saved",
              iconPack: "feather",
              icon: "icon-check",
              color: "success",
              position: "top-right",
            });
            this.$emit("close");
          } else {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: res.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
              position: "top-right",
            });
          }
        });
    },
    checkValue(event, row, index) {
      console.log("Changed value:", event);
      console.log("Row Data:", row);
      console.log("Index:", index);

      let qtyTotal = row.quantity; // Total available quantity
      let totalDistributed = row.vehicle_distribution.reduce(
        (sum, item) => sum + Number(item.quantity),
        0
      );
      let remainingQty =
        qtyTotal -
        totalDistributed +
        Number(row.vehicle_distribution[index].quantity);

      // Ensure input is not negative or greater than total quantity
      let newQty = Math.max(0, Math.min(event, remainingQty));

      // Assign the adjusted value
      row.vehicle_distribution[index].quantity = newQty;

      // Recalculate total distributed quantity
      totalDistributed = row.vehicle_distribution.reduce(
        (sum, item) => sum + Number(item.quantity),
        0
      );

      // If totalDistributed exceeds qtyTotal, adjust the largest value
      if (totalDistributed > qtyTotal) {
        let maxIndex = row.vehicle_distribution.findIndex(
          (item) =>
            item.quantity ===
            Math.max(...row.vehicle_distribution.map((i) => i.quantity))
        );

        row.vehicle_distribution[maxIndex].quantity -=
          totalDistributed - qtyTotal;
      }

      // Recalculate qtyCounting
      let qtyCounting =
        qtyTotal -
        row.vehicle_distribution.reduce(
          (sum, item) => sum + Number(item.quantity),
          0
        );
      row.qtyCounting = qtyCounting;

      // Notify if quantity exceeds total allowed
      if (qtyCounting < 0) {
        this.$vs.notify({
          title: "Error",
          text: "Quantity cannot be more than the total quantity",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
          position: "top-right",
        });

        row.vehicle_distribution[index].quantity = Math.max(
          0,
          row.quantity - totalDistributed
        );
      }

      // If all values are zero, set the first one to total qty
      if (row.vehicle_distribution.every((item) => item.quantity === 0)) {
        row.vehicle_distribution[0].quantity = qtyTotal;
      }

      console.log("Updated vehicle distribution:", row.vehicle_distribution);
      console.log("Remaining qtyCounting:", row.qtyCounting);
    },
    resetData() {
      this.table.dataLine = JSON.parse(JSON.stringify(this.table.dataLineTemp));
    },
  },
};
</script>
<style>
.vl {
  border-left: 6px solid black;
  height: 100%;
}
</style>
